import React, { useState, useEffect } from 'react';
import { InstantValModal as Modal } from '@strikelabs/luna';
import { get, set, remove } from 'tiny-cookie';

import {
  SWITCHED_TO_INSTANT_VAL,
  HVR_POSTCODE,
  HVR_STARTED,
} from 'constants/cookies';

const InstantValModal = () => {
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    if (get(HVR_STARTED)) setShowModal(true);
  }, []);

  const getInstantValLink = () => {
    const postcode = get(HVR_POSTCODE);
    let link = '/online-valuation';
    if (postcode) {
      link = `/online-valuation?postcode=${postcode}`;
    }
    return link;
  };

  const dismissCookies = () => {
    remove(HVR_STARTED);
    remove(HVR_POSTCODE);
  };

  const handleCtaClick = () => {
    const link = getInstantValLink();
    dismissCookies();
    set(SWITCHED_TO_INSTANT_VAL, '1', { expires: '30m' });
    window.location = link;
  };

  const handleClickAway = () => {
    dismissCookies();
    setShowModal(false);
  };

  return (
    <Modal
      isOpen={showModal}
      onClose={handleClickAway}
      handleCtaClick={handleCtaClick}
    />
  );
};

export default InstantValModal;
