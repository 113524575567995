import React, { useEffect } from 'react';
import { ThemeProvider } from 'styled-components';
import PropTypes from 'prop-types';
import { Footer, Header, Toaster } from '@strikelabs/luna';
import { identifier } from '@strikelabs/pax';
import {
  ABTestProvider,
  useWebSend,
  useAuth,
  useWebLogin,
} from '@strikelabs/vega';
import { identifyUser } from '@strikelabs/sputnik';

import GlobalProvider from 'provider';
import themeV2 from 'theme';
import GlobalStyle from 'theme/global';
import CookieBanner from 'components/CookieBanner';
import InstantValModal from 'components/Modals/InstantValModal';
import { LayoutContainer } from './style';

const Layout = ({ children, noHeader, noFooter, noCookieBanner }) => {
  const { user, logout } = useAuth();

  const { handleLogout } = useWebLogin({
    onLogout: logout,
    useSendHook: useWebSend,
  });

  useEffect(() => {
    if (user) {
      identifyUser(user.id);
    }
  }, [user]);

  return (
    <div {...identifier({ name: 'container', requireId: false })}>
      <GlobalProvider>
        <ABTestProvider
          uaId={process.env.GATSBY_UA_ID}
          useSendHook={useWebSend}
          baseUrl={process.env.GATSBY_API_URL}
        >
          <ThemeProvider theme={themeV2}>
            <GlobalStyle />
            <Toaster gutter={8} limit={3} />
            {!noCookieBanner && <CookieBanner url="/legal/privacy-policy" />}
            {!noHeader && (
              <Header
                hubUrl={`${process.env.GATSBY_HUB_URL}`}
                authUrl={`${process.env.GATSBY_AUTH_URL}`}
                profile={user}
                handleLogout={handleLogout}
                showPostcodeForm
                showPBBanner={true}
                newsURL={`https://${process.env.GATSBY_BASE_HOSTNAME}/latest-news/moving-to-purplebricks`}
              />
            )}
            <div>
              <LayoutContainer>{children}</LayoutContainer>
            </div>
            {!noFooter && <Footer showPBLink />}
            <InstantValModal />
          </ThemeProvider>
        </ABTestProvider>
      </GlobalProvider>
    </div>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  noHeader: PropTypes.bool,
  noFooter: PropTypes.bool,
  noGuideLine: PropTypes.bool,
  noCookieBanner: PropTypes.bool,
};

export default Layout;
